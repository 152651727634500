/* eslint-disable class-methods-use-this */
import routes from '../routes/routes';
import UrlParser from '../routes/url-parser';

class App {
  constructor({ content }) {
    this._content = content;
    this._initialAppShell();
  }

  _initialAppShell() { }

  async renderPage() {
    const url = UrlParser.parseActiveUrlWithCombiner();
    const page = routes[url in routes ? url : '/error'];
    this._content.innerHTML = await page;
    this._handlerSkipLink();
    this._handlerResetPasswordTimer();
  }

  _handlerSkipLink() {
    const skipLinkElem = document.querySelector('.skip');
    skipLinkElem.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('#main-content').focus();
    });
  }

  _handlerResetPasswordTimer() {
    const getTimer = localStorage.getItem('TIMER_RESET_PASSWORD');
    const time = Number(getTimer ?? 0);
    let tmp = time;
    if (time > 0) {
      const intervalId = setInterval(() => {
        tmp--;
        localStorage.setItem('TIMER_RESET_PASSWORD', tmp);
        if (tmp <= 0) clearInterval(intervalId);
      }, 1000);
    }
  }
}

export default App;
