import createRequest from '../views/pages/create-request';
import detailPayment from '../views/pages/detail-payment';
import error from '../views/pages/error';
import home from '../views/pages/home';
import login from '../views/pages/login';
import resetPassword from '../views/pages/reset-password';

const routes = {
  '/': home,
  '/error': error,
  '/login': login,
  '/reset-password': resetPassword,
  '/detail-payment/:id': detailPayment,
  '/create-request': createRequest,
};

export default routes;
